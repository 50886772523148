import { Container } from '@mui/material';
import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
import DocketForm from '../../components/Forms/DocketForm';
import DocketCreationModal from '../../components/Models/DocketCreationModal';
import { useCircuitDetail } from '../../hooks/circuit';
import { useDocket } from '../../hooks/docket';
import Alert from '../../components/Common/Alert';
import { formatDateTime } from '../../utils/shortcuts';
import { useUser } from '../../hooks/user';
import toast from 'react-hot-toast';

export default function DocketAddPage(props) {
    const { user } = useUser();
    const [searchParams] = useSearchParams();
    const currentParams = Object.fromEntries([...searchParams]);
    let navigate = useNavigate();
    const circuit_id = searchParams.get('circuit');
    const [defaultMethod, setDefaultMethod] = useState('POST')
    const [forcedDocket, setForcedDocket] = useState(false)
    const [ignoreMaintenance, setIgnoreMaintenance] = useState(false)
    const { circuitData, circuitIsLoading } = useCircuitDetail(circuit_id);
    const { handleDocketAction, docketActionIsLoading } = useDocket(defaultMethod);
    const [modal, setModal] = useState(false)
    const [docData, setDocData] = useState(null);
    // console.log(circuitData)
    // console.log(circuit_id)

    const handleSubmit = async (values, { resetForm }) => {
        const toastId = toast.loading('Processing...');
        const { fiber_team_leader, dtechnician, third_party_docket_no, problem, dhirer_name, comment, docketed_by, docketed_by_contact, dstatus, circuit, provider, is_scheduled, scheduled_at } = values;
        // console.log(values)
        const res = await handleDocketAction({
            "fiber_team_leader": fiber_team_leader,
            "technician": dtechnician,
            "third_party_docket_no": third_party_docket_no,
            "problem": problem,
            "hirer_name": dhirer_name,
            "comment": comment,
            "docketed_by": docketed_by,
            "docketed_by_contact": docketed_by_contact,
            // "status": dstatus,
            "history": {},
            "circuit": circuit,
            "provider": provider,

            is_scheduled: is_scheduled,
            scheduled_at: is_scheduled ? scheduled_at : null,
            status: is_scheduled ? 'Scheduled' : dstatus,
        });

        // console.log('save res', res)
        if (res.success) {
            toast.success(res.detail, { id: toastId });

            setDocData(res?.data)
            setModal(true)
            resetForm({ values: '' });
            // navigate(`/docket`);
        } else {
            toast.error(res.detail, { id: toastId })
        }
        // console.log('data --> ', res);
    }

    const handleDocketReopen = async (docket_code) => {
        setDefaultMethod('PATCH')
        const res = await handleDocketAction({
            "id": docket_code,
            'status': 'Pending',
            "action": `reopend`, // Marking Docket as Reopend 
            "remarks": `Docket Re-Opened.`,
        });
        if (res.success) {
            toast.success('Docket Reopened!');
            navigate(`/docket/${docket_code}`)
        } else {
            toast.error(res.detail)
        }
    }

    const renderDocketCreationForm = (data) => {
        if (data?.active_docket?.status) {
            return <>
                <Alert
                    title='Already has a active docket.'
                    text={`Close the Docket ${data?.active_docket?.code} First to Continue...`}
                    type='primary'
                />
                <Link className='btn btn-info' to={`/docket/${data?.active_docket?.code}`}>View Docket</Link>
                <Link className='btn btn-warning mx-2' to={`/docket/close/${data?.active_docket?.code}`}>Close Previous</Link>
            </>
        } else {
            if (data?.has_recent_docket?.status && !forcedDocket) {
                return <>
                    <Alert
                        title='Docket Reopen Window'
                        text={`The Last Docket is Closed Recently, You can reopen until ${formatDateTime(data?.has_recent_docket?.opened_until, 'Do MMMM YYYY, h:mm:ss a')}`}
                        type='info'
                    />
                    <div className='d-flex gap-1'>
                        <Link className='btn btn-info' to={`/docket/${data?.has_recent_docket?.code}`}>View Docket</Link>
                        <button className='btn btn-danger' onClick={() => setForcedDocket(true)} disabled={docketActionIsLoading}>Create New</button>
                        <button className='btn btn-warning' onClick={() => handleDocketReopen(data?.has_recent_docket?.code)} disabled={docketActionIsLoading}>Reopen the Docket</button>
                    </div>
                    <br />
                    <code>*Double Click to Reopen the Docket!</code>
                </>
            } else {
                return <DocketForm
                    data={circuitData?.data}
                    docketData={{
                        provider: parseInt(currentParams?.provider) ?? '',
                        problem: parseInt(currentParams?.problem) ?? '',
                        comment: currentParams?.comment ?? '',
                        docketed_by: currentParams?.docketed_by ?? '',
                        docketed_by_contact: currentParams?.docketed_by_contact ? currentParams?.docketed_by_contact.replace(' 91', '') : '',
                    }}
                    isLoading={docketActionIsLoading}
                    handleSubmit={handleSubmit}
                    submitBtnLabel='Create Docket'
                />

            }
        }

    }
    return (
        <>
            <Helmet>
                <title>Dockets - New Docket</title>
            </Helmet>
            <Container maxWidth="xxl" disableGutters>

                {
                    <PageBreadcrumbs
                        title="New Docket"
                        // alt={`${circuitData && !circuitIsLoading && `Create New docket (${circuitData.data.code}`})`}
                        alt={circuitData && !circuitIsLoading ? `Create new docket (${circuitData?.data?.code})` : 'New docket'}
                        history={[{ name: 'Docket', to: '/docket' }]}
                    />
                }
                <div className='container'>

                    <div className="row ">
                        {/* Show  Maintenance Warning */}
                        {circuitData?.data?.status === 'Under Maintenance' && circuitData?.data?.is_under_maintenance && <Alert
                            title='Warning: Under Maintenance!'
                            text={`This Circuit is in Under Maintenance from ${formatDateTime(circuitData?.data?.get_maintenance_date?.start_from, 'MMM Do YYYY, h:mm:ss a')} to ${formatDateTime(circuitData?.data?.get_maintenance_date?.end_to, 'MMM Do YYYY, h:mm:ss a')}.`}
                            type='warning'
                        />}

                        {!circuitData?.data?.is_under_maintenance || ignoreMaintenance ? <div className="col mt-2">
                            {circuitData && !circuitIsLoading && user?.permissions?.dockets?.can_create_adv_docket && renderDocketCreationForm(circuitData.data)}
                            {!user?.permissions?.dockets?.can_create_adv_docket && <Alert
                                title='You do not have the permission!'
                                text='Contact to administrator.'
                                type='warning'
                            />}
                            {docData && <DocketCreationModal
                                modal={modal}
                                setModal={setModal}
                                cirData={circuitData?.data}
                                docData={docData}
                            />}
                            {/* {circuitData && !circuitIsLoading && <DocketForm
                                data={circuitData.data}
                                docketData={{}}
                                isLoading={circuitIsLoading ?? docketActionIsLoading}
                                handleSubmit={handleSubmit}
                                submitBtnLabel='Create Docket'
                            />}

                            {docData && <DocketCreationModal
                                modal={modal}
                                setModal={setModal}
                                cirData={circuitData?.data}
                                docData={docData}
                            />} */}
                        </div> :
                            <div>
                                <button className='btn btn-danger' onClick={() => setIgnoreMaintenance(true)}>Continue Creating Docket</button>
                            </div>}
                    </div>
                </div>
            </Container>
        </>

    )
}
